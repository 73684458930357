import store from '@/store';
import kybStore from '../store/index';
let isRegistered = false;
export function registerStore() {
  if (!isRegistered) {
    store.registerModule('kyb', kybStore);
    isRegistered = true;
  }
}

export default function init() {
  //do initial work
  registerStore();
}
