import Contact from './index.vue';

const routes = [
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    children: [
      {
        path: 'list',
        name: 'ContactList',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactList.vue')
      },
      {
        path: 'details',
        name: 'ContactDetails',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactDetails.vue')
      },
      {
        path: 'detailForm',
        name: 'ContactDetailForm',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactDetailForm.vue')
      },
      {
        path: 'accountTypeForm',
        name: 'ContactAccountTypeForm',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactAccountTypeForm.vue')
      },
      {
        path: 'addressForm',
        name: 'ContactAddressForm',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactAddressForm.vue')
      },
      {
        path: 'accountForm',
        name: 'ContactAccountForm',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactAccountForm.vue')
      },
      {
        path: 'success',
        name: 'ContactCreatedSuccess',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/ContactCreatedSuccess.vue')
      }
    ]
  }
];

export default routes;
