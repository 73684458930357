import * as VueGoogleMaps from 'vue2-google-maps';
import Vue from 'vue';
import { getEnv } from '@/utils/env';

Vue.use(VueGoogleMaps, {
  load: {
    key: getEnv('VUE_APP_GMAPS_KEY'),
    libraries: 'places'
  }
});
