<template>
  <div class="mini-account-selector">
    <div class="scroll">
      <ul
        class="acccounts"
        data-testid="mini-accounts-list">
        <!-- <li
          :class="{
            selected: getSelectedAccount && getSelectedAccount.id === account.id,
            account: true
          }"
          v-for="account in getGroupedAccounts"
          :key="'account-selector-' + account.id"
          @click="selectAccount(account)">
          <div class="account__img center">
            {{ account.label[0] }}
          </div>
          <div>
            <div class="account__label">
              {{ account.label }}
            </div>
            <div class="account__label">
              {{ account.availableBalance | currency }}
            </div>
          </div>
        </li> -->
        <li class="account__add-account" @click="addAccount">
          <span class="icon-spend-small">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
          </span>
          <p>{{ $t('selectAcc_screenTitle') }}</p>
        </li>
      </ul>
    </div>
    <div class="right" v-if="env.VUE_APP_ENV !== 'prod'">
      <el-tag type="warning">
        TEST Mode
      </el-tag>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { getEnv } from '@/utils/env';
export default {
  computed: {
    ...mapGetters('account', ['getGroupedAccounts']),
    ...mapGetters('account', ['getSelectedAccount']),
    env() {
      return getEnv();
    }
  },
  mounted() {
    
    const container = this.$el.querySelector('.scroll');
    const el = this.$el.querySelector('.selected.account');
    if(el){
      const rect = el.getBoundingClientRect()
      container.scrollTo(rect.left, rect.top)
    }
  },
  methods: {
    ...mapMutations('account', ['setSelectedAccount', 'setAddAccountBackPage']),
    selectAccount(a) {
      if (!this.getSelectedAccount || a.id !== this.getSelectedAccount.id) {
        this.setSelectedAccount(a);
        this.$emit('accountChanged', a);
      }
    },
    addAccount() {
      this.setAddAccountBackPage(this.$router.currentRoute.fullPath);
      this.$router.push('/account/create');
    }
  }
};
</script>
<style lang="scss">
.mini-account-selector {
  background: white;
  border-bottom: 1px solid #ECECEC;
  padding-left: 289px;
  height: 105px;
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 999;
  
  .scroll {
    overflow-x: auto; 
    height: 120px;
    width: 100%;
  }
  .acccounts {
    display: flex;
    text-align: center;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 105px;
    &.has-scrollbar {
      overflow-x: scroll;
    }
    li {
      height: 105px;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
    }
    .account {
      width: 230px;
      min-width: 230px;
      padding: 20px 6px 6px 6px;
      cursor: pointer;
      
      background-color: white;
      &__img {
        background: var(--branding-primary);
        color: white;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        display: inline-flex;
        margin-right: 22px;
      }

      &.selected {
        border-bottom: 6px solid var(--branding-primary);
        color: var(--branding);
        opacity: 1;

        .account__img {
          background: var(--branding-primary);
          width: 38px;
          height: 38px;
          font-size: 18px;
        }
        .account__label {
          font-size: 16px;
          opacity: 1;
        }
      }

      &__label {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 3px;
        letter-spacing: 0.02em;
        max-width: 130px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        opacity: 0.6;
      }

      &__add-account {
        padding: 20px 6px 6px 6px;
        cursor: pointer;
        width: 200px;
        min-width: 200px;
        background-color: white;
        .icon-spend-small, p {
          opacity: 0.6;
        }
        &:hover {
          .icon-spend-small, p {
            opacity: 1;
          }
        }
        .icon-spend-small {
          font-size: 36px;
          display: inline-block;
          margin-right: 22px;
          border: 1px dashed #141414;
          border-radius: 30px;
          .path1:before {
            opacity: 0;
          }
          .path2, .path3 {
            &:before {
              color: var(--branding);
            }
          }
        }

        p {
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
          font-weight: 500;
          transition: 0.2s;
        }
      }
    }
  }
  .right {
    background-color: white;
    border-left: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    padding: 0 60px 0 32px;
    height: 105px;

    .el-tag.el-tag--warning {
      color: white;
      background-color: var(--orange2);
      padding: 8px 10px;
      font-size: 14px;
    }
  }
}
</style>
