<template>
  <div id="main-container">
    <side-bar ref="sidebar" />
    <div class="container dashboard-container">
      <mobile-header
        @showProfileDrawer="showProfileDrawer"
        @showBusinessDrawer="showBusinessDrawer"
        v-if="getIsMobileLayout" />
      <account-selector v-else />
      <div class="dashboard-container__body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SideBar from '@/components/SideBar.vue'
import AccountSelector from '@m/dashboard/components/AccountSelector';
import MobileHeader from '@m/dashboard/components/MobileHeader';
import { networkOffline, networkOnline } from '@/utils/general';

const online = () => {
  networkOnline('layout')
}

const offline = () => {
  networkOffline('layout')
}

export default {
  components: { SideBar, AccountSelector, MobileHeader },
  computed: {
    ...mapGetters(['getIsMobileLayout'])
  },
  methods: {
    showProfileDrawer() {
      this.$refs.sidebar.goToProfile();
    },
    showBusinessDrawer() {
      this.$refs.sidebar.goToBusiness();
    }
  },
  created() {
    window.addEventListener('online', online);
    window.addEventListener('offline', offline);
  },
  beforeDestroy() {
    window.removeEventListener('online', online);
    window.removeEventListener('offline', offline);
  }
}
</script>