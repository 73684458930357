import CheckDeposit from './index.vue';
const routes = [
  {
    path: '/check-deposit',
    name: 'CheckDeposit',
    component: CheckDeposit,
    children: [
      {
        path: 'actions',
        name: 'CheckDeposit-actions',
        component: () => import('./views/CheckDepositAction.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'status',
        name: 'CheckDeposit-status',
        component: () => import('./views/CheckDepositStatus.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'contacts',
        name: 'CheckDeposit-ContactList',
        component: () => import('./views/Contacts.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'form',
        name: 'CheckDepositForm',
        component: () => import('./views/CheckDeposit.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'preview',
        name: 'CheckDepositPreview',
        component: () => import('./views/CheckDepositPreview.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'success',
        name: 'CheckDepositSuccess',
        component: () => import('./views/CheckDepositSuccess.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;
