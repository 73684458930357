import store from '@/store';
import cardStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('card', cardStore);
  } else {
    if (!cardStore.registered) {
      store.registerModule('card', cardStore, {
        preserveState: !!store.state['card']
      });
      cardStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  registerStore();
}
