import store from '@/store';
import contactStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('contact', contactStore);
  } else {
    if (!contactStore.registered) {
      store.registerModule('contact', contactStore, {
        preserveState: !!store.state['contact']
      });
      contactStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  registerStore();
}
