/**
 * @typedef {Object} payState
 * @property {string|null} selectedPaymentType
 * @property {Object|null} completedPaymentDetails
 * @property {Object|null} payDetails
 */
export default {
  selectedPaymentType: null,
  completedPaymentDetails: null,
  payDetails: null
};
