import Vue from 'vue';
import { showLoader } from '../utils/loader';
import { apiErrorMessage } from '../utils/general';
import { mapGetters, mapMutations } from 'vuex';
import { getBrandConfig, getEnv } from '@/utils/env';

Vue.mixin({
  computed: {
    ...mapGetters('user', ['getProgramDetails']),
    logoUrl() {
      if (
        this.getProgramDetails &&
        this.getProgramDetails.brand &&
        this.getProgramDetails.brand.landscapeLogo &&
        this.getProgramDetails.brand.landscapeLogo.length !== 0
      ) {
        return this.getProgramDetails.brand.landscapeLogo;
      } else {
        return require('../assets/images/logos/Logo-new.svg');
      }
    },
    isHellosignEnabled() {
      const clientId = getEnv('VUE_APP_HELLO_SIGN_CLIENT_ID');
      return  !!(clientId && clientId !== '' && clientId.length !== 0);
    }
  },
  methods: {
    ...mapMutations(['updateLogoutPopupStatus']),
    showLoader,
    apiErrorMessage,
    goToDashboard() {
      this.$router.push('/dashboard/home');
    },
    goToPreviousScreen() {
      this.$store.commit('userClickedBack', true);
      setTimeout(() => {
        this.$router.back();
      }, 0);
    },
    logoutAndRedirect() {
      this.updateLogoutPopupStatus(true);
    },
    goToWelcomeScreen() {
      this.$router.push('/');
    },
    drawerClose() {
      this.$emit('close');
    },
    drawerBack() {
      this.$emit('back');
    },
    drawerPush(url) {
      this.$emit('goTo', url);
    },
    drawerReplace(url) {
      this.$emit('goTo', url, 'replace');
    },
    openHelp() {
      const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      const supportMail = getBrandConfig('supportMail');
      if(regex.test(supportMail)) {
        const win = window.open(supportMail, '_blank');
        win.focus();
      } else {
        window.location.href = 'mailto:'+supportMail;
      }
    }
  }
});
