<template>
  <div class="mobile-header">
    <div class="mobile-header__top">
      <div class="initialsInfoLight"
           @click="showSidebar()">
        <span class="icon-burger-menu" />
      </div>
      <div class="mobile-header__logo">
        <img class="logo"
             :src="logoUrl"
             alt="logo">
      </div>
      <div class="initialsInfoLight" @click="goToProfile">
        <span>{{ shortName }}</span>
      </div>
    </div>
    <div class="mobile-header__name" v-if="showBusinessName" @click="goToBusiness">
      <div class="initialsInfoLight">
        <span v-if="selectedAccount.type !== 'personalChecking'">
          {{ getSelectedBusinessName }}
        </span>
        <span v-else>
          {{ getPersonDetails.firstName + ' ' +getPersonDetails.lastName }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('account', ['getSelectedAccount']),
    shortName() {
      let name = '';
      if(this.getPersonDetails.firstName) {
        name = this.getPersonDetails.firstName[0]; 
      }
      if(this.getPersonDetails.lastName) {
        name+=this.getPersonDetails.lastName[0]; 
      }
      return name;
    },
    showBusinessName() {
      return this.$route.name === 'DashboardHome' && this.getIsMobileLayout;
    },
    selectedAccount() {
      return this.getSelectedAccount;
    },
    getSelectedBusinessName() {
      if(this.getSelectedBusiness != null) {
        return this.getSelectedBusiness.legalName;
      }
      return ''
    }
  },
  methods: {
    ...mapMutations(['setIsSidebarVisible', 'getIsMobileLayout']),
    showSidebar() {
      this.setIsSidebarVisible(true);
    },
    goToProfile() {
      this.$emit('showProfileDrawer');
    },
    goToBusiness() {
      if(this.selectedAccount.type !== 'personalChecking') {
        this.$emit('showBusinessDrawer');
      }
    }
  }
};
</script>
<style lang="scss">
  .mobile-header {
    background: #fff;
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    &__logo {
      flex: 1;
      display: flex;
      justify-content: center;
      img {
        max-width: Min(75%, 160px);
      }
    }
    &__name {
      padding: 0 10px 10px;

      .initialsInfoLight {
        width: auto;
        height: 46px;
        justify-content: flex-start;
        padding: 0 20px;
      }
    }
  }
</style>
