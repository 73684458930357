import Vue from 'vue';
/**
 * @typedef {import('./state').payState} payState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;
export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get payment type
     * @param {payState} state
     */
    getPaymentType(state) {
      return state.selectedPaymentType;
    },
    /**
     * get completedPaymentDetails
     * @param {payState} state
     * @returns {payState['completedPaymentDetails']}
     */
    getCompletedPaymentDetails(state) {
      return state.completedPaymentDetails;
    },
    /**
     * get pay details
     * @param {payState} state
     * @returns {payState['payDetails']}
     */
    getPayDetails(state) {
      return state.payDetails;
    }
  },
  mutations: {
    /**
     * update payment type
     * @param {payState} state
     * @param {payState['selectedPaymentType']} payload
     */
    updateSelectedPaymentType(state, payload) {
      state.selectedPaymentType = payload;
    },
    /**
     * update completed payment details
     * @param {payState} state
     * @param {payState['completedPaymentDetails']} payload
     */
    updateCompletedPaymentDetails(state, payload) {
      state.completedPaymentDetails = payload;
    },
    /**
     * update payment details
     * @param {payState} state
     * @param {payState['payDetails']} payload
     */
    setPayDetails(state, payload) {
      state.payDetails = payload;
    }
  },
  actions: {
    /**
     * intrabank pay
     * @param {*} _
     * @param {*} payload
     */
    intrabankPay({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/send/intrabank', payload)
          .then(({ data }) => {
            commit('updateCompletedPaymentDetails', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * ach pay
     * @param {*} _
     * @param {*} payload
     */
    achPay({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/send/ach', payload)
          .then(({ data }) => {
            commit('updateCompletedPaymentDetails', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * check pay
     * @param {*} _
     * @param {*} payload
     */
    checkPay({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/send/check', payload)
          .then(({ data }) => {
            commit('updateCompletedPaymentDetails', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * check pay
     * @param {*} _
     * @param {*} payload
     */
    domesticPay({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/send/wire', payload)
          .then(({ data }) => {
            commit('updateCompletedPaymentDetails', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * send a card
     * @param {Object} param0
     * @param {Object} payload
     */
    sendCard({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http.post('v1/send/card',payload)
          .then(({data}) => {
            commit('updateCompletedPaymentDetails', data);
            resolve(data)
          })
          .catch((e) => {
            reject(e);
          });
      })
    }
  },
};
