import Dashboard from './index.vue';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: 'home',
        name: 'DashboardHome',
        component: () => import('./views/DashboardHome.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('./views/UserProfile.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'editUserAddress',
        name: 'EditUserAddress',
        component: () => import('./views/EditUserAddress.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;
