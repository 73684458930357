import Vue from 'vue';
import {
  formatDate,
  contactName,
  transactionAmount,
  formatPhoneNumber,
  mask,
  currencyFormatter
} from '../utils/general';

Vue.filter('date', formatDate);
Vue.filter('contactName', contactName);
Vue.filter('transactionAmount', transactionAmount);
Vue.filter('phone', formatPhoneNumber);
Vue.filter('mask', mask);
Vue.filter('currency', currencyFormatter);