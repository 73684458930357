/**
 * @typedef {Object} kycState
 * @property {onfidoDetails} onfidoDetails
 * @property {kycData} kycData
 * @property {string | null} selectedAccountType
 */
export default {
  /**
   * @typedef {Object} onfidoDetails
   * @property {string| null} token
   * @property {onfidoSubmitDetails} submitDetails
   */
  onfidoDetails: {
    token: null,
    /**
     * @typedef {Object} onfidoSubmitDetails
     * @property {string|null} status
     * @property {string} message
     */
    submitDetails: {
      status: null,
      message: ''
    }
  },
  /**
   * @typedef {Object} kycData
   * @property {string|null} id
   * @property {string|null} personId
   * @property {string|null} status
   * @property {string|null} reviewMessage
   * @property {kycDataResults} results
   * @property {string|null} createdAt
   * @property {string|null} modifiedAt
   *
   * @typedef {Object} kycDataResults
   * @property {string|null} idv
   * @property {string|null} address
   * @property {string|null} fraud
   */
  kycData: {
    id: null,
    personId: null,
    status: null,
    reviewMessage: null,
    results: {
      idv: null,
      address: null,
      fraud: null
    },
    createdAt: null,
    modifiedAt: null
  },
  selectedAccountType: null
};
