import Account from './index.vue';

const routes = [
  {
    path: '/account',
    name: 'Account',
    component: Account,
    children: [
      {
        path: 'list',
        name: 'AccountListDashboard',
        meta: {
          layout: 'ao',
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () =>
          import(
            /* webpackChunkName: "AccountList" */ './views/AccountList.vue'
          )
      },
      {
        path: 'all',
        name: 'AccountListLarge',
        component: () =>
          import(
            /* webpackChunkName: "AccountListLarge" */ './views/AccountList.vue'
          )
      },
      {
        path: 'create',
        name: 'AddAccount',
        meta: {
          layout: 'ao'
        },
        component: () =>
          import(/* webpackChunkName: "AddAccount" */ './views/AddAccount.vue')
      },
      {
        path: 'details',
        name: 'AccountDetails',
        component: () =>
          import(
            /* webpackChunkName: "AccountDetails" */ './views/AccountDetails.vue'
          )
      },
      {
        path: 'setup',
        name: 'AccountSetupAccount',
        meta: {
          layout: 'ao'
        },
        component: () =>
          import(
            /* webpackChunkName: "SetupAccount" */ './views/SetupAccount.vue'
          )
      },
      {
        path: 'accountCreated',
        meta: {
          layout: 'ao',
          preventBrowserBack: true
        },
        name: 'AccountCreatedSuccess',
        component: () =>
          import(
            /* webpackChunkName: "AccountCreated" */ './views/AccountCreated.vue'
          )
      },
      {
        path: 'statements',
        name: 'Statements',
        component: () => import('./views/Statements.vue')
      }
    ]
  }
];

export default routes;
