<template>
  <router-view />
</template>
<script>
import init from './setup';
init();
export default {
  name: 'Card',
  created() {}
};
</script>
