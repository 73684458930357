import store from '@/store';
import checkDepositStore from '../store/index';
let isRegistered = false;
export function registerStore() {
  if (!isRegistered) {
    store.registerModule('checkDeposit', checkDepositStore);
    isRegistered = true;
  }
}

export default function init() {
  //do initial work
  registerStore();
}
