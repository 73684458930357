import Vue from 'vue';
/**
 * @typedef {import('./state').kybState} kybState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */
const http = Vue.$http;

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get business list from store
     * @param {kybState} state
     * @returns {kybState['bussinessDetails']}
     */
    getBusinessList(state) {
      return state.bussinessDetails;
    },
    /**
     * get business details fetched status
     * @param {kybState} state
     * @returns {kybState['bussinessDetailsFetchedStatus']}
     */
    getBussinessDetailsFetchedStatus(state) {
      return state.bussinessDetailsFetchedStatus;
    },
    /**
     * get business details fetched status
     * @param {kybState} state
     * @returns {kybState['selectedBusinessType']}
     */
    getSelectedBusiness(state) {
      return state.selectedBusinessType;
    },
    /**
     * get kyb details
     * @param {kybState} state
     * @returns {kybState['kybDetails']}
     */
    getKybDetails(state) {
      return state.kybDetails;
    },
    /**
     * get disclosureStatus url and status
     * @param {kybState} state
     * @returns {kybState['agreementUrlAndStatus']}
     */
    getAgreementUrlAndStatus(state) {
      return state.agreementUrlAndStatus;
    },
    /**
     * get owners details
     * @param {kybState} state
     * @returns {kybState['owners']}
     */
    getOwnersDetails(state) {
      return state.owners;
    },
    /**
     * get create page owner details
     * @param {kybState} state
     * @returns {kybState['createPageOwnerDetails']}
     */
    getCreatePageOwnersDetail(state) {
      return state.createPageOwnerDetails;
    }
  },
  mutations: {
    /**
     * set business list
     * @param {kybState} state
     * @param {kybState['bussinessDetails']} payload
     */
    setBusinessList(state, payload) {
      state.bussinessDetails = payload;
      state.bussinessDetailsFetchedStatus = 'fetched';
    },
    /**
     * set selected business
     * @param {kybState} state
     * @param {kybState['selectedBusinessType']} payload
     */
    setSelectedBusiness(state, payload) {
      state.selectedBusinessType = payload;
    },
    /**
     * set kyb details
     * @param {kybState} state
     * @param {kybState['kybDetails']} payload
     */
    setKybDetails(state, payload) {
      state.kybDetails = payload;
    },
    /**
     * set disclosureStatus url and status
     * @param {kybState} state
     * @param {kybState['agreementUrlAndStatus']} payload
     */
    setAgreementUrlAndStatus(state, payload) {
      state.agreementUrlAndStatus = payload;
    },
    /**
     * set owners
     * @param {kybState} state
     * @param {kybState['owners']} payload
     */
    setOwnersDetails(state, payload) {
      state.owners = payload;
    },
    /**
     * set create page owner details
     * @param {kybState} state
     * @param {kybState['createPageOwnerDetails']} payload
     */
    setCreatePageOwnerDetails(state, payload) {
      state.createPageOwnerDetails = payload;
    }
  },
  actions: {
    /**
     * fetch all business list
     * @param {Object} param0
     * @param {Function} param0.commit
     */
    listAllBusiness({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business')
          .then(({ data }) => {
            commit('setBusinessList', data.data);
            console.log(data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * create new business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {*} payload
     */
    createBusiness({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/business', payload)
          .then(({ data }) => {
            commit('setSelectedBusiness', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     * @param {businessId} payload.businessId
     * @param {*} payload.business
     */
    updateBusiness({ commit }, { businessId, business }) {
      return new Promise((resolve, reject) => {
        http
          .patch('v1/business/' + businessId, business)
          .then(({ data }) => {
            commit('setSelectedBusiness', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch business
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchBusiness(_, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business/' + businessId)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * fetch
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchKybDetails({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get('v1/business/' + businessId + '/kyb')
          .then(({ data }) => {
            commit('setKybDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * submit kyb
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    submitKyb({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/business/' + businessId + '/kyb', { businessId })
          .then(({ data }) => {
            commit('setKybDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * Get disclosureStatus URL & Status
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchHelloSignURL({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/business/${businessId}/agreement`)
          .then(({ data }) => {
            commit('setAgreementUrlAndStatus', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * list all owners
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} businessId
     */
    fetchAllOwners({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        http
          .get(`v1/owner?businessId=${businessId}`)
          .then(({ data }) => {
            commit('setOwnersDetails', data.data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * create owner
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     */
    createOwner(_, payload) {
      return new Promise((resolve, reject) => {
        http
          .post('v1/owner', payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update owner
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} payload
     */
    updateOwner(_, { ownerId, details }) {
      return new Promise((resolve, reject) => {
        http
          .patch(`v1/owner/${ownerId}`, details)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * submit owner kyc
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} ownerId
     */
    submitOwnerKyc(_, ownerId) {
      return new Promise((resolve, reject) => {
        http
          .post(`v1/owner/${ownerId}/kyc`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
