/**
 * @typedef {Object} fundState
 * @property {plaidTokenDetails} plaidTokenDetails
 * @property {Object} pullFundDetails
 * @property {Object} pullFundResponseDetails
 * @property {Object} moveFundDetails
 * @property {Object} moveFundResponseDetails
 */
export default {
  /**
   * @typedef {Object} plaidTokenDetails
   * @property {string} linkToken
   * @property {string} expiresIn
   */

  /**
   * @type {plaidTokenDetails}
   */
  plaidTokenDetails: null,
  /**
   * pull fund details
   */
  pullFundDetails: null,
  /**
   * pull fund details
   */
  pullFundResponseDetails: null,
  /**
   * pull fund details
   */
  moveFundDetails: null,
  /**
   * pull fund details
   */
  moveFundResponseDetails: null
};
